import Icon from 'src/components/ui/Icon'
import Button from 'src/components/ui/Button'

import type { Toast } from './ToastContext'
import { useToastContext } from './ToastContext'

interface DisplayToastsProps {
  toasts: Toast[]
}

export const DisplayToasts = ({ toasts }: DisplayToastsProps) => {
  const { removeToast } = useToastContext()

  if (!toasts || !toasts.length) {
    return <></>
  }

  return (
    <div className="flex flex-col z-[9999] w-full gap-2 fixed top-0 sm:top-[11rem] sm:absolute sm:left-[65%] restructure-small-desktop:w-[375px]">
      {toasts.map((toast, idx) => {
        const { text, variant, image, subtext } = toast

        return (
          <div
            key={idx}
            className={`flex items-center justify-between bg-white p-4 rounded-sm drop-shadow-md ${variant}`}
          >
            <div className="flex items-center gap-4">
              {image !== undefined ? (
                <img src={image} alt={subtext} width={60} height={60} />
              ) : (
                <Icon
                  name={variant === 'success' ? 'SuccessCheck' : 'ErrorWarning'}
                  width={20}
                  height={20}
                  className="!overflow-visible"
                />
              )}
              <div className="flex flex-col">
                <span
                  className={`${
                    variant === 'success' ? 'text-[#101010]' : 'text-[#E3262F]'
                  } ${subtext !== undefined && 'font-bold'}`}
                >
                  {text}
                </span>
                {subtext !== undefined && (
                  <div className="mt-1 text-sm line-clamp-2 w-auto">
                    {subtext}
                  </div>
                )}
              </div>
            </div>
            <Button
              className={`flex h-[32px] ${
                subtext !== undefined && 'absolute top-1 right-1'
              }`}
              icon={<Icon name="CloseToast" width={32} height={32} />}
              iconPosition="right"
              onClick={() => {
                removeToast(toast)
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
