import axios from 'axios'
import { useCallback } from 'react'
import { useMobile } from 'src/hooks/useMobile'

import { useLinxUser } from './useLinxUser'

type ProductClickProps = {
  clickUrl: string
  interactionType?: 'ADD_TO_CART'
}

type Params = {
  userId?: string
  deviceId: string
  source: string
  interactionType?: string
}

export const useLinxProductClick = () => {
  const { getLinxUser } = useLinxUser()
  const linxUser = getLinxUser()
  const { isMobile } = useMobile()

  const sendProductClickEvent = useCallback(
    ({ clickUrl, interactionType }: ProductClickProps) => {
      let params: Params = {
        deviceId: linxUser.deviceId,
        source: !isMobile ? 'desktop' : 'mobile',
      }

      if (linxUser.loggedIn) {
        params = { ...params, userId: (linxUser as { id: string }).id }
      }

      if (interactionType) {
        params = { ...params, interactionType }
      }

      const url = clickUrl?.includes('chaordic')
        ? clickUrl
        : `https://api.linximpulse.com${clickUrl}`

      return axios.get(`${url}`, { params })
    },
    [isMobile, linxUser]
  )

  return { sendProductClickEvent }
}
