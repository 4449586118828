export function getLoginOriginType(loginOriginPage?: string): string {
  if (!loginOriginPage) {
    return ''
  }

  const url = new URL(loginOriginPage)

  switch (true) {
    case url.pathname === '/':
      return 'homepage'

    case url.pathname.endsWith('/p'):
      return 'página de produto'

    case url.pathname.includes('/campanha/'):
      return 'página de campanha'

    case url.pathname.includes('/esportes/'):
      return 'página de departamento'

    case url.pathname.includes('/institucional/') ||
      url.pathname.includes('/servicos/'):
      return 'página institucional'

    default:
      return 'página de lista de produtos'
  }
}
