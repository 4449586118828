import { Image } from 'src/components/ui/Image'

export const SafetyTransaction = () => (
  <div
    aria-label="Seção com ícones que asseguram que o site possui suas transações seguras"
    className="flex flex-col text-restructure-primary-inverted desktop-overline-medium gap-4
    flex-wrap
    restructure-maxMobile:pt-8
    restructure-maxTablet:pt-8
    restructure-maxMobile:pb-12
    restructure-maxTablet:pb-12"
  >
    Transação segura
    <div className="flex flex-row gap-4 items-center flex-wrap">
      <Image
        className="w-[31px] h-[22px]"
        alt="Ícone Lets Encrypt"
        aria-label="Ícone de segurança Lets Encrypt"
        src="https://decathlonstore.vtexassets.com/arquivos/lets.png"
        loading="lazy"
        width={31}
        height={22}
      />

      <a
        aria-label="Link de redirecionamento e ícone de segurança PCI"
        href="https://secure.vtex.com/?an=decathlon"
        className="min-w-[16px] min-h-[15px]"
      >
        <Image
          className="w-[16px] h-[15px]"
          alt="Ícone PCI"
          aria-label="Ícone de segurança PCI"
          src="https://decathlonstore.vtexassets.com/arquivos/pci.png"
          loading="lazy"
          width={16}
          height={15}
        />
      </a>

      <Image
        className="w-[61px] h-[14px]"
        alt="Ícone Konduto"
        aria-label="Ícone de segurança Konduto"
        src="https://decathlonstore.vtexassets.com/arquivos/konduto.png"
        loading="lazy"
        width={61}
        height={14}
      />
    </div>
  </div>
)
