import * as amplitude from '@amplitude/analytics-browser'

type AmplitudeEvent = {
  eventName: string
  eventData: Record<string, any> | undefined
}

const AMPLITUDE_API_KEY = 'fa79c244a3f2441593b2fee565ec63eb' // [DEV] Ecommerce Brazil Web

const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
  amplitude.init(AMPLITUDE_API_KEY, { serverZone: 'EU' })
}

export const dispatchAmplitudeEvent = ({
  eventName,
  eventData,
}: AmplitudeEvent) => {
  if (!isBrowser) {
    return
  }

  amplitude.track(eventName, eventData)
}
